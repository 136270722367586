<template>
  <div class="wrap">
    <van-nav-bar
      :title="
        transferType === 'into'
          ? $t('page.transferInto')
          : $t('page.transferOut')
      "
    >
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar>
    <div class="top">
      <div class="title">{{ $t("balanceTransfer") }}</div>
      <div class="input">
        <span>{{ verConfig.moneyType }}</span>
        <input type="number" placeholder="0.00" v-model="amount" />
      </div>
      <div class="balance">
        {{
          `${$t("availableBalance")}: ${$money(
            transferType === "into"
              ? userInfo.money || "0.00"
              : balance || "0.00"
          )}`
        }}
      </div>
    </div>
    <div class="rate_wrap">
      {{ `${$t("depositRate")}:` }}
      <span>{{ `${rate || 0}%/30${$t("days")}` }}</span>
    </div>
    <div class="btn" @click="transfer">
      {{
        transferType === "into" ? $t("btn.transferInto") : $t("btn.transferOut")
      }}
    </div>
  </div>
</template>

<script>
import { NavBar, Icon, Toast } from "vant";
import { $post, $get } from "@/utils/request";
export default {
  props: {
    transferType: {
      type: "into" | "out",
    },
  },
  components: { [NavBar.name]: NavBar, [Icon.name]: Icon },
  data() {
    return {
      userInfo: {},
      amount: "",
      reqNum: 0,
      balance: "0.00",
      rate: 0,
    };
  },
  created() {
    this.getUserInfo();
    this.getFinancialBalance();
  },
  methods: {
    getUserInfo() {
      this.reqNum++;
      this.$api.getUserInfo(
        (res) => {
          this.reqNum--;
          this.userInfo = res;
        },
        (err) => {
          this.reqNum--;
        }
      );
    },
    async getFinancialBalance() {
      this.reqNum++;
      try {
        const res = await $get("/yuebao/balance");
        this.reqNum--;
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          const { balance, rate } = data;
          this.balance = balance;
          this.rate = rate;
          return;
        }
        Toast(msg);
      } catch (error) {
        this.reqNum--;
      }
    },
    async transfer() {
      Toast.loading();
      const url =
        this.transferType === "into"
          ? "/yuebao/transfer/in"
          : "/yuebao/transfer/out";
      try {
        const res = await $post(url, { amount: this.amount });
        const { ret, msg } = res.data;
        Toast.clear();
        if (ret === 1) {
          Toast(this.$t("hint.success"));
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
  },
  watch: {
    reqNum(value) {
      if (value === 0) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f7f8fc;
  .top {
    padding: calc(16rem / 16);
    background: #fff;
    .title {
      font-size: calc(14rem / 16);
      color: #334360;
    }
    .input {
      display: flex;
      // align-items: center;
      align-items: flex-end;
      padding: calc(11rem / 16);
      border-bottom: 1px solid #eeeff2;
      > span {
        font-size: calc(20rem / 16);
      }
      > input {
        border: none;
        margin-left: 6px;
      }
    }
    .balance {
      margin-top: calc(11rem / 16);
      font-size: calc(14rem / 16);
      color: #a7aab6;
    }
  }
  .rate_wrap {
    margin: calc(11rem / 16) 0;
    padding: 0 calc(23rem / 16);
    background: #fff;
    line-height: calc(65rem / 16);
    font-size: calc(16rem / 16);
    font-weight: 500;
    color: #23292e;
    > span {
      font-size: calc(10rem / 16);
    }
  }
  .btn {
    width: 90vw;
    margin: calc(20rem / 16) auto;
    background: var(--nav-background);
    border: 1px solid #dedede;
    color: #fff;
    padding: calc(10rem / 16) 0;
    border-radius: 4px;
    text-align: center;
  }
}
</style>