<template>
  <Transfer transferType="into" />
</template>

<script>
import Transfer from "../../components/transfer.vue";
export default {
  components: { Transfer },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
</style>